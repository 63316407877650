import React from "react";
import Header from "../header/header";
import "./introduction.css";
import { withRouter } from "react-router";

const Introduction = (props) => {
  return (
    <>
      <Header />
      <section className="d-flex justify-content-center justify-content-md-end container__text--welcome">
        <div className="text-center text-md-left home__text--welcome">
          <div>
            <h3 className="text-white mb-1">Bienvenidos al</h3>
            <h3 className="text-white home__text--second my-3">
              Programa de formación virtual para el fortalecimiento de
              conocimientos en
            </h3>
            <h1 className="home__text--security">
              el mejoramiento de las condiciones de trabajo
            </h1>
          </div>
          <button
            className="btn home__text--button"
            onClick={() => props.history.push("/inscripcion")}
          >
            Inscríbete aquí
          </button>
        </div>
      </section>
    </>
  );
};

export default withRouter(Introduction);

export const checkboxes = [
  {
    label: "Seguridad y salud en el trabajo y terapias antiestrés",
    value: "4529192960",
  },
  {
    label: "Prevención y control de enfermedades contagiosas",
    value: "4529192961",
  },
  {
    label: "Prevención y manejo de emergencias",
    value: "4529192962",
  },
  {
    label: "Seguridad y salud en el trabajo y ambiente organizacional",
    value: "4529192963",
  },
  {
    label: "Asesoría en el sistema general de seguridad social",
    value: "4529192964",
  },
  {
    label: "Conozca y actúe con prevención contra el COVID-19",
    value: "4529192965",
  },
  {
    label: "Capacitación en prevención en consumo de sustancias",
    value: "4529192966",
  },
];

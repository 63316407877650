import React from "react";
import "./banner.css";

const Banner = () => {
  return (
    <div className="banner bg-info text-center">
      <div className="filter" />
      <h3>Es el momento de actualizarte</h3>
      <h2>
        Una oportunidad formativa para crecer en tu actividad de trabajo,
        brindar un mejor servicio y marcar la diferencia.
      </h2>
    </div>
  );
};

export default Banner;

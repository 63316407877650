import React from "react";
import "./formRow.css";

const FormRow = (props) => {
  const { id, type, placeHolder, register, patternValue, patternMessage } = props;

  return (
    <div className="form-group">
      <input
        id={id}
        type={type}
        className="form-control"
        placeholder={placeHolder}
        {...register(id, {
          required: "Este campo es requerido",
          pattern: {
            value: patternValue,
            message: patternMessage,
          },
        })}
      />
    </div>
  );
};

export default FormRow;

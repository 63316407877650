import React from "react";
import Banner from "../../components/banner/banner";
import Courses from "../../components/courses/courses";
import Introduction from "../../components/introduction/introduction";
import "./homeView.css";

const HomeView = () => {
  return (
    <>
      <div className="container__home">
        <Introduction />
      </div>
      <Banner />
      <Courses />
    </>
  );
};

export default HomeView;

import React from "react";
import FormCheck from "../../components/introduction/formCheck/formCheck";
import FormRow from "../../components/introduction/formRow/formRow";
import { useForm } from "react-hook-form";
import survey from "../../api/survey";
import { checkboxes } from "../../consts/form";
import ModalForm from "../../components/modal/modal";
import iconBack from "../../../assets/icons/arrow-left.png";
import { withRouter } from "react-router";
import "./inscriptionView.css";

const InscriptionView = (props) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const [modal, setModal] = React.useState(false);
  const [showOther1, setShowOther1] = React.useState(false);
  const [valueSelect1, setValueSelect1] = React.useState("0");
  const [errorSelect1, setErrorSelect1] = React.useState(false);
  const [showOther2, setShowOther2] = React.useState(false);
  const [valueSelect2, setValueSelect2] = React.useState("0");
  const [errorSelect2, setErrorSelect2] = React.useState(false);

  React.useEffect(() => {
    if (valueSelect2 === "4775533262") {
      setShowOther2(true);
    } else {
      setShowOther2(false);
    }
  }, [valueSelect2]);

  React.useEffect(() => {
    if (valueSelect1 === "4775479339") {
      setShowOther1(true);
    } else {
      setShowOther1(false);
    }
  }, [valueSelect1]);

  const onSubmit = (data) => {
    // console.log(valueSelect1);
    if (valueSelect1 !== "0" && valueSelect2 !== "0") {
      // console.log({ ...data, medio: valueSelect1, cargo: valueSelect2 });
      setErrorSelect1(false);
      setErrorSelect2(false);
      setModal(true);
      survey({ ...data, medio: valueSelect1, cargo: valueSelect2 });
    } else if (valueSelect1 === "0") {
      setErrorSelect1(true);
    } else if (valueSelect2 === "0") {
      setErrorSelect2(true);
    }
  };

  return (
    <>
      {modal && <ModalForm isOpen={modal} />}

      <div className="inscription__container d-flex">
        <div className="back" onClick={() => props.history.push("/")}>
          <img src={iconBack} alt="back" />
        </div>
        <div className="col col-md-6 col-lg-6 inscription__background" />
        <div className="col-12 col-md-6 col-lg-6 inscription__card px-0">
          <div className="inscription__form__container text-center">
            <h5>Bienvenidos al programa</h5>
            <h2>Seguridad Social Integral</h2>
            <div className="inscription__inputs">
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormRow
                  type={"text"}
                  id={"nombre"}
                  placeHolder={"Nombre"}
                  register={register}
                />
                {errors.nombre && (
                  <span className="text-danger">{errors.nombre.message}</span>
                )}
                <FormRow
                  type={"text"}
                  id={"apellido"}
                  placeHolder={"Apellido"}
                  register={register}
                />
                {errors.apellido && (
                  <span className="text-danger">{errors.apellido.message}</span>
                )}
                <FormRow
                  type={"text"}
                  id={"identificacion"}
                  placeHolder={"No. identificación"}
                  register={register}
                />
                {errors.identificacion && (
                  <span className="text-danger">
                    {errors.identificacion.message}
                  </span>
                )}
                <FormRow
                  type={"text"}
                  id={"correo"}
                  placeHolder={"Correo"}
                  register={register}
                  patternValue={/\S+@\S+\.\S+/}
                  patternMessage={
                    "El valor ingresado no coincide con el formulario de correo electrónico"
                  }
                />
                {errors.correo && (
                  <span className="text-danger">{errors.correo.message}</span>
                )}
                <FormRow
                  type={"text"}
                  id={"celular"}
                  placeHolder={"Número de celular"}
                  register={register}
                />
                {errors.celular && (
                  <span className="text-danger">{errors.celular.message}</span>
                )}
                <div className="division" />
                <h4 className="mt-4">Selecciona los cursos que deseas tomar</h4>
                {checkboxes.map((field, index) => (
                  <FormCheck
                    label={field.label}
                    value={field.value}
                    register={register}
                    getValues={getValues}
                    key={field.value}
                    index={index}
                  />
                ))}

                {errors.cursos && (
                  <span className="text-danger">{errors.cursos.message}</span>
                )}
                <div className="division mt-2" />
                <h4 className="mt-4">
                  ¿A través de qué medio se enteró de la oferta académica?
                </h4>
                <select
                  className="form-control my-4"
                  value={valueSelect1}
                  // defaultValue=""
                  // id="medio"
                  name="medio"
                  onChange={(e) => setValueSelect1(e.target.value)}
                >
                  <option value="0">Seleccione el medio...</option>
                  <option value="4775479335">Facebook.</option>
                  <option value="4775479336">
                    Página web del Ministerio de Trabajo.
                  </option>
                  <option value="4775479337">
                    Página web de una entidad gubernamental externa a
                    Mintrabajo.
                  </option>
                  <option value="4775479338">Por un tercero</option>
                  <option
                    value="4775479339"
                    // onClick={() => setShowOther2({ ...showOther, medio: true })}
                  >
                    Otro (por favor especifique)
                  </option>
                </select>
                {errorSelect1 && (
                  <span className="text-danger">Por favor seleccione uno</span>
                )}
                {showOther1 && (
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      id="otherMedio"
                      rows="3"
                      placeholder="Escriba el medio..."
                      {...register("textMedio")}
                    ></textarea>
                  </div>
                )}

                <h4 className="mb-4">¿Cuál es su cargo actual?</h4>

                <select
                  className="form-control my-4"
                  value={valueSelect2}
                  // defaultValue=""
                  // id="cargo"
                  name="cargo"
                  onChange={(e) => setValueSelect2(e.target.value)}
                  // {...register("cargo", { required: "select one option" })}
                >
                  <option value="0">Seleccione su cargo...</option>
                  <option value="4775533258">Taxista</option>
                  <option value="4775533259">Domiciliario Productos</option>
                  <option value="4775533260">Domiciliario Servicios</option>
                  <option
                    value="4775533262"
                    // onClick={() => setShowOther({ ...showOther, cargo: true })}
                  >
                    Otro (por favor especifique)
                  </option>
                </select>
                {errorSelect2 && (
                  <span className="text-danger">Por favor seleccione uno</span>
                )}
                {showOther2 && (
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      id="otherCargo"
                      rows="3"
                      placeholder="Escriba su cargo..."
                      {...register("textCargo")}
                    ></textarea>
                  </div>
                )}

                <button
                  className="btn inscription__form--button w-100 mt-2"
                  type="submit"
                >
                  Enviar
                </button>
              </form>
              <p className="form__terms mb-5 mb-md-3">
                Acepto{" "}
                <a
                  className="form__terms link"
                  href="https://talentumline.edu.co/assets/pdf/Terminos_condiciones_politicas.pdf"
                  target="_balnk"
                >
                  Terminos y Condiciones
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(InscriptionView);

import React from "react";
import icon_FB from "../../../assets/icons/icon_Fb.png";
import icon_IG from "../../../assets/icons/icon_IG.png";
import icon_IN from "../../../assets/icons/icon_Linkedin.png";
import "./header.css";

const Header = () => {
  return (
    <header className="header__home pr-2 pr-md-4">
      <div className="navbar navbar-expand-md align-items-center align-items-md-end">
        <a href="https://www.facebook.com/Linetalentum/" target="_balnk">
          <img className="d-block w-100" src={icon_FB} alt="icon1" />
        </a>
        <a href="https://www.instagram.com/talentumline/" target="_balnk">
          <img className="d-block w-100" src={icon_IG} alt="icon2" />
        </a>
        <a href="https://www.linkedin.com/company/talentum-line/" target="_balnk">
          <img className="d-block w-100" src={icon_IN} alt="icon3" />
        </a>
      </div>
    </header>
  );
};

export default Header;

import React from "react";
import IconCheckBig from "../../../assets/icons/checkBig.svg";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import { withRouter } from "react-router";
import "./modal.css";

const ModalForm = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      className="modal-dialog modal-dialog-centered modal-lg modal-form"
    >
      <ModalBody>
        <div className="text-center">
          <img
            src={IconCheckBig}
            width="99.2"
            height="99.2"
            className="mb-1"
            alt="check"
          />
          <p className="message-modal tex-center px-4">
            Se ha enviado correctamente
          </p>
        </div>
      </ModalBody>
      <ModalFooter className="modal-footer row justify-content-center">
        <Button className="btn-modal" onClick={() => props.history.push("/")}>
          Aceptar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default withRouter(ModalForm);
import React from "react";
import CollapseCourse from "../collapseCoruse/collapseCourse";
import "./coursesMobile.css";

const CoursesMobile = () => {
  return (
    <div className="container__collapse">
      <CollapseCourse
        title={"Seguridad y salud en el trabajo y terapias antiestrés"}
        description={
          "Desarrollar conocimientos básicos sobre la seguridad y salud en el trabajo y terapias antiestrés como una herramienta que permita alcanzar el equilibrio y bienestar físico y mental para la ejecución óptima de las tareas laborales y extra laborales."
        }
        timeCourse={"12 horas"}
      />
      <CollapseCourse
        title={"Prevención y control de enfermedades contagiosas"}
        description={
          "Que los participantes adquieran conocimientos en la prevención y control de enfermedades infectocontagiosas, con el fin de adquirir conductas que ayuden a disminuir o evitar la propagación de estas."
        }
        timeCourse={"12 horas"}
      />
      <CollapseCourse
        title={"Prevención y manejo de emergencias"}
        description={
          "Capacitar a los participantes para que con formación y criterio adquieran las destrezas necesarias para estar preparados y puedan ayudar a las comunidades a prepararse a entregar una respuesta eficaz y eficiente en caso de un desastre."
        }
        timeCourse={"12 horas"}
      />
      <CollapseCourse
        title={"Seguridad y salud en el trabajo y ambiente organizacional"}
        description={
          "Este curso busca capacitar al participante sobre la importancia del ambiente de trabajo como factor protector preventivo del estrés laboral y la salud mental, así como un agente importante en la productividad y el mejoramiento en la calidad de vida y del servicio."
        }
        timeCourse={"12 horas"}
      />
      <CollapseCourse
        title={"Asesoría en el sistema general de seguridad social"}
        description={
          "Presentar a los estudiantes, el funcionamiento del Sistema de Gestión de la Seguridad Social (SGSS) en Colombia, identificando sus principales actores y funciones así como los beneficios de pertenecer y ser parte del mismo desde la perspectiva de la seguridad social en salud, pensión y riesgos laborales."
        }
        timeCourse={"12 horas"}
      />
      <CollapseCourse
        title={"Conozca y actúe con prevención contra el COVID-19"}
        description={
          "Socializar las medidas preventivas establecidas por el Ministerio de Salud y demás organismos oficiales, sobre el manejo y control del riesgo de la enfermedad COVID-19 para el sector transporte y servicios domiciliarios, de manera didáctica para asegurar su comprensión y debida práctica en su entorno laboral y social."
        }
        timeCourse={"12 horas"}
      />
      <CollapseCourse
        title={"Capacitación en prevención en consumo de sustancias"}
        description={
          "Presentar los elementos básicos sobre la prevención del consumo de alcohol y otras sustancias psicoactivas que orienten a reconocer y desmitificar creencias sobre el tema, reflexionando en conceptos relacionados con las sustancias psicoactivas lícitas e ilícitas, así como reconocer sus efectos en el entorno individual, familiar y laboral, de manera que se identifiquen algunas acciones básicas para enfrentar una situación de este orden."
        }
        timeCourse={"12 horas"}
      />
    </div>
  );
};

export default CoursesMobile;

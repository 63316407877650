import { url, token } from "../consts/request";

const survey = (data) => {
  const mappedArray = data.cursos.map((info) => {
    const newArray = { choice_id: info };
    return newArray;
  });

  const sendAnswerMedio = () => {
    let answer = [];
    if (data.medio === "4775479339") {
      answer = [
        {
          other_id: data.medio,
          text: data.textMedio,
        },
      ];
    } else {
      answer = [
        {
          choice_id: data.medio,
        },
      ];
    }

    return answer;
  };

  const sendAnswerCargo = () => {
    let answer = [];
    if (data.cargo === "4775533262") {
      answer = [
        {
          other_id: data.cargo,
          text: data.textCargo,
        },
      ];
    } else {
      answer = [
        {
          choice_id: data.cargo,
        },
      ];
    }

    return answer;
  };

  let raw = JSON.stringify({
    ip_address: "127.0.0.1",
    pages: [
      {
        id: "173757119",
        questions: [
          {
            id: "688694856",
            answers: [
              {
                text: data.nombre,
              },
            ],
          },
          {
            id: "688695112",
            answers: [
              {
                text: data.apellido,
              },
            ],
          },
          {
            id: "688695525",
            answers: [
              {
                text: data.identificacion,
              },
            ],
          },
          {
            id: "688695700",
            answers: [
              {
                text: data.correo,
              },
            ],
          },
          {
            id: "709745487",
            answers: [
              {
                text: data.celular,
              },
            ],
          },
          {
            id: "688732902",
            answers: mappedArray,
          },
          // Respuestas del select de medio
          {
            id: "726112560",
            answers: sendAnswerMedio()
          },
          // Respuestas del select de cargo
          {
            id: "726120842",
            answers: sendAnswerCargo()
          },
        ],
      },
    ],
  });

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: raw,
  };
  fetch(url, requestOptions)
    .then((result) => console.log(result))
    .catch((error) => console.log(error));

  // console.log(raw)
};

export default survey;

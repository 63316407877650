import React from "react";
import imgAntiestres from "../../../../assets/images/courses/antiestres.png";
import imgEnfermedades from "../../../../assets/images/courses/enfermedades.png";
import imgPrevencion from "../../../../assets/images/courses/prevencion.png";
import imgAmbiente from "../../../../assets/images/courses/ambiente.png";
import imgSeguridad from "../../../../assets/images/courses/seguridad.png";
import imgCovid from "../../../../assets/images/courses/covid.png";
import imgConsumo from "../../../../assets/images/courses/consumo.png";
import CardCourse from "../cardCourse/cardCourse";
import "./coursesWeb.css";

const CoursesWeb = () => {
  return (
    <div className="container-fluid px-5">
      <div className="row justify-content-center">
        <CardCourse
          title={"Seguridad y salud en el trabajo y terapias antiestrés"}
          timeCourse={"12 horas"}
          image={imgAntiestres}
          description={
            "Desarrollar conocimientos básicos sobre la seguridad y salud en el trabajo y terapias antiestrés como una herramienta que permita alcanzar el equilibrio y bienestar físico y mental para la ejecución óptima de las tareas laborales y extra laborales."
          }
        />
        <CardCourse
          title={"Prevención y control de enfermedades contagiosas"}
          timeCourse={"12 horas"}
          image={imgEnfermedades}
          description={
            "Que los participantes adquieran conocimientos en la prevención y control de enfermedades infectocontagiosas, con el fin de adquirir conductas que ayuden a disminuir o evitar la propagación de estas."
          }
        />
        <CardCourse
          title={"Prevención y manejo de emergencias"}
          timeCourse={"12 horas"}
          image={imgPrevencion}
          description={
            "Capacitar a los participantes para que con formación y criterio adquieran las destrezas necesarias para estar preparados y puedan ayudar a las comunidades a prepararse a entregar una respuesta eficaz y eficiente en caso de un desastre."
          }
        />
        <CardCourse
          title={"Seguridad y salud en el trabajo y ambiente organizacional"}
          timeCourse={"12 horas"}
          image={imgAmbiente}
          description={
            "Este curso busca capacitar al participante sobre la importancia del ambiente de trabajo como factor protector preventivo del estrés laboral y la salud mental, así como un agente importante en la productividad y el mejoramiento en la calidad de vida y del servicio."
          }
        />
        <CardCourse
          title={"Asesoría en el sistema general de seguridad social"}
          timeCourse={"12 horas"}
          image={imgSeguridad}
          description={
            "Presentar a los estudiantes, el funcionamiento del Sistema de Gestión de la Seguridad Social (SGSS) en Colombia, identificando sus principales actores y funciones así como los beneficios de pertenecer y ser parte del mismo desde la perspectiva de la seguridad social en salud, pensión y riesgos laborales."
          }
        />
        <CardCourse
          title={"Conozca y actúe con prevención contra el COVID-19"}
          timeCourse={"12 horas"}
          image={imgCovid}
          description={
            "Socializar las medidas preventivas establecidas por el Ministerio de Salud y demás organismos oficiales, sobre el manejo y control del riesgo de la enfermedad COVID-19 para el sector transporte y servicios domiciliarios, de manera didáctica para asegurar su comprensión y debida práctica en su entorno laboral y social."
          }
        />
        <CardCourse
          title={"Capacitación en prevención en consumo de sustancias"}
          timeCourse={"12 horas"}
          image={imgConsumo}
          description={
            "Presentar los elementos básicos sobre la prevención del consumo de alcohol y otras sustancias psicoactivas que orienten a reconocer y desmitificar creencias sobre el tema, reflexionando en conceptos relacionados con las sustancias psicoactivas lícitas e ilícitas, así como reconocer sus efectos en el entorno individual, familiar y laboral, de manera que se identifiquen algunas acciones básicas para enfrentar una situación de este orden."
          }
        />
      </div>
    </div>
  );
};

export default CoursesWeb;

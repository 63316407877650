import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeView from "./app/views/homeView/homeView";
import InscriptionView from "./app/views/inscriptionView/inscriptionView";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <HomeView />
        </Route>
        <Route path="/inscripcion">
          <InscriptionView />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

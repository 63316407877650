import React from "react";
import "./courses.css";
import CoursesMobile from "./coursesMobile/coursesMobile";
import CoursesWeb from "./coursesWeb/coursesWeb";

const Courses = () => {
  const [widthResolution, setWidthResolution] = React.useState(
    window.innerWidth
  );

  React.useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidthResolution(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const renderCoursesPerResolution = () => {
    if (widthResolution < 768) {
      return <CoursesMobile />;
    } else {
      return <CoursesWeb />;
    }
  };

  return (
    <div className="courses">
      <h1 className="courses__title text-center">Cursos</h1>
      {renderCoursesPerResolution ()}
    </div>
  );
};

export default Courses;

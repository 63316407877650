import React from "react";
import iconArrowDown from "../../../../assets/icons/arrow-down.png";
import iconArrowUp from "../../../../assets/icons/arrow-up.png";
import iconTime from "../../../../assets/icons/clock.png";
import "./collapseCourse.css";

const CollapseCourse = (props) => {
  const { title, description, timeCourse } = props;
  const [showMore, setShowMore] = React.useState(false);

  let show = showMore ? "inline" : "none";
  return (
    <div className={showMore ? "card__collapse expanded" : "card__collapse"}>
      <div className="card__collapse--title">
        <div>{title}</div>
        <p className="text-muted" style={{ display: show }}>
          {description}
        </p>
        <div className={showMore ? "d-flex align-items-center" : "d-none"}>
          <img
            src={iconTime}
            alt="clock"
            style={{ width: "21px", height: "21px" }}
          />
          <div className="card__collapse--time ml-2">{timeCourse}</div>
        </div>
      </div>
      <div
        className={
          !showMore ? "card__collapse--icon" : "card__collapse--icon-expanded"
        }
      >
        <img
          src={showMore ? iconArrowUp : iconArrowDown}
          alt="drop"
          onClick={() => setShowMore(!showMore)}
        />
      </div>
    </div>
  );
};

export default CollapseCourse;

import React from "react";
import iconArrowRight from "../../../../assets/icons/arrow-right.png";
import iconArrowLeft from "../../../../assets/icons/arrow-left.png";
import iconTime from "../../../../assets/icons/clock.png";
import "./cardCourse.css";

const CardCourse = (props) => {
  const { title, timeCourse, image, description } = props;

  const [showInfo, setShowInfo] = React.useState(false);

  const renderCardSide = () => {
    if (!showInfo) {
      return (
        <>
          <img src={image} className="card-img-top" alt="..." onClick={() => setShowInfo(true)} onMouseEnter={() => setShowInfo(true)} />
          <div className="card-body d-flex flex-column" onClick={() => setShowInfo(true)} onMouseEnter={() => setShowInfo(true)}>
            <h5 className="card-title">{title}</h5>
            <div className="card-action mt-auto">
              <div className="d-flex align-items-center">
                <img
                  src={iconTime}
                  alt="clock"
                  style={{ width: "21px", height: "21px" }}
                />
                <div className="card-time ml-2">{timeCourse}</div>
              </div>
              <div className="card-button card-button-right">
                <img
                  src={iconArrowRight}
                  alt="arrow"
                  // onClick={() => setShowInfo(true)}
                />
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="card-body d-flex flex-column" onClick={() => setShowInfo(false)} onMouseLeave={() => setShowInfo(false)}>
          <h5 className="card-title">{title}</h5>
          <p className="card-description text-muted">{description}</p>
          <div className="card-action mt-auto">
            <div className="d-flex align-items-center">
              <img
                src={iconTime}
                alt="clock"
                style={{ width: "21px", height: "21px" }}
              />
              <div className="card-time ml-2">{timeCourse}</div>
            </div>
            <div className="card-button card-button-left">
              <img
                src={iconArrowLeft}
                alt="arrow"
                // onClick={() => setShowInfo(false)}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="col-12 col-md-4 col-lg-3 mb-5 pb-5">
      <div className="card mx-auto courses__card">{renderCardSide()}</div>
    </div>
  );
};

export default CardCourse;
